import React from "react";
import Modal from "./layout";
import AddServiceModel from "./add-service-model/model";
import ServiceMenuModel from "./service-menu-model/service-menu-model"
import EmployeeMenuModel from "./manage-employees-model/employee-menu-model"
import ChangeEmployeeRoleModel from "./manage-employees-model/change-employee-role-model"
import RemoveEmployeeModel from "./manage-employees-model/remove-employee-model"
import DeactivateEmployeeModel from "./manage-employees-model/deactivate-employee-model"
import UpdateEmployeeModel from "./manage-employees-model/update-employee-model"
import ManageServiceMenuModel from "./service-menu-model/manage-service-menu-model"
import SetupServiceModel from "./setup-service-model/model";
import UpdateServiceName from "./manage-services-update-model/update-service-name-model";
import RemoveServiceMember from "./manage-services-update-model/remove-service-member-model";
import ServiceMemberType from "./manage-services-update-model/service-member-type-model";
import UpdateUserServiceDetails from "./manage-services-update-model/update-user-service-details-model";
import UploadNewContract from "./manage-services-update-model/upload-new-contract-model";
import UpdateServiceMemberType from "./manage-services-update-model/update-service-member-type-model";
import AddServiceMember from "./manage-services-update-model/add-service-member-model";
import UpdateServiceCategory from "./manage-services-update-model/update-service-category-model";
import ServiceInfo from "./setup-service-model/tabs/tabs/service_info";
import ServiceSetupMenuModel from "./service-menu-model/service-setup-menu-model"
import SubmitServiceModel from "./service-model/model";
import ServiceSuccessModel from "./success-model/model";
import ServicesSuccessModel from "./success-model/service-success-model";
import ServicesActivateModel from "./success-model/service-activate-model";
import MissingServiceModel from "./service-missing-model/model";
import ServiceRequestModel from "./service-request-model/model";
import OptInServiceModel from "./service-Opt-in-model/model";
import OptOutServiceModel from "./service-Opt-out-model/model";
import ChangeServiceModel from "./change-service-model/model";
import ServiceChangeSuccessModel from "./service-change-success-model/model";
import ServiceOptInSuccessModel from "./Opt-in-success-model/model";
import ServiceOptOutSuccessModel from "./Opt-out-success-model/model";
import HistoryModel from "./history-model/historyModel";
import GiveFeedbackModel from "./give-feedback-model/give-feedback-model";
import ServiceFeedbackInfoModel from "./service-feedback-info-model/ServiceFeedbackInfoModel";
import ServiceRequestInfoModel from "./service-request-info-model/ServiceRequestInfoModel";
import ServiceRequestInfoComModel from "./service-request-info-com-model/ServiceRequestInfoComModel";
import EmployeeRequestsModel from "./employee-requests-model/employee-requests-model";
import EmployeeFeedbacksModel from "./employee-feedbacks-model/employee-feedbacks-model";
import EmployeeSubmissionsModel from "./employee-submissions-model/employee-submissions-model";
import ServiceSubmissionInfoModel from "./service-submission-info-model/service-submission-info-model";
import UserRequestsTableModel from "./user-requests-table-model/user-requests-table-model";
import ServiceRequestsTableModel from "./service-request-table-model/service-request-table-model";
import ServiceDetailsFeedbackModel from "./service-details-feedback-model/service-details-feedback-model";
import CompanySettingModel from "./setting-model/company-setting-model";
import ManageServiceModel from "./manage-services-model/manage-services-model";
import SubmittedServicesModel from "./submitted-services-model/submitted-services.model";
import EmployeeHistoryModel from "./history-model/employeeHistoryModel";
import EmpServiceHistoryModel from "./history-model/empServiceHistoryModel";
import SubmittedServicesInfoModel from "./submitted-services-info-model/submitted-services-info-model";
import SimilarServices from "./opportunity/SimilarServices";
import ManageContractModel from "./manage-contract-model/Manage-contract-model";
import EditManageContractModel from "./manage-contract-model/Edit-manage-contract-model";
import ConfirmModel from "./confirm-model/confirm-model"
import ManageDataScope from "./manage-data-scope/manageDataScope"

const BasicModal = (
  {
    popup,
    setPopup,
    setModel,
    serviceType,
    state,
    setId,
    id,
    feedBackId,
    setState,
    noicon,
    empId,
    tablename,
    serviceId,
    feedBacksids,
    RequestserviceID,
    feedServiceId,
    dashboard,
    companyempid,
    feedid,
    submissionsData,
    setNewServiceAdded,
    handleSetup,
    serviceName,
    setServiceName,
    serviceCategory,
    setServiceCategory,
    userId,
    userType,
    userRole,
    empID,
    empReport,
    similarServices,
    serviceData,
    activeTab,
    setConfirmDelete,
    setConfirmIgnore,
  }) => {
    return (
      <Modal show={popup} hide={setPopup}>
        {(() => {
          switch (state) {
            case 1: {
              return <SubmitServiceModel setstate={setState} popup={popup} setPopup={setPopup}/>;
            }
            case 2: {
              return <ServiceSuccessModel serviceType={serviceType} setstate={setState} setPopup={setPopup} />;
            }
            case 3: {
              return <MissingServiceModel setstate={setState} setPopup={setPopup} />;
            }
            case 4: {
              return <ServiceRequestModel setstate={setState} id={id} setId={setId} setPopup={setPopup} />;
            }
            case 5: {
              return <OptInServiceModel setstate={setState} setPopup={setPopup} id={id} setId={setId}/>;
            }
            case 6: {
              return <OptOutServiceModel setstate={setState} setPopup={setPopup} id={id} setId={setId} serviceData={serviceData}/>;
            }
            case 7: {
              return <ChangeServiceModel setstate={setState} setPopup={setPopup} id={id} serviceData={serviceData} />;
            }
            case 8: {
              return <ServiceChangeSuccessModel setstate={setState} setPopup={setPopup} />;
            }
            case 9: {
              return <ServiceOptInSuccessModel setstate={setState} setPopup={setPopup} />;
            }
            case 10: {
              return <ServiceOptOutSuccessModel setstate={setState} setPopup={setPopup} />;
            }
            case 11: {
              return <HistoryModel setstate={setState} setPopup={setPopup} />;
            }
            case 12: {
              return <GiveFeedbackModel setstate={setState} setPopup={setPopup} id={id} setId={setId} />;
            }
            case 13: {
              return <ServiceFeedbackInfoModel setstate={setState} setPopup={setPopup}  dashboard={dashboard} id={feedid} />;
            }
            case 14: {
              return <ServiceRequestInfoModel setstate={setState} setPopup={setPopup} id={id} setId={setId} dashboard={dashboard}/>;
            }
            case 15: {
              return <EmployeeRequestsModel setstate={setState} setPopup={setPopup} empId={empId} dashboard={dashboard} />;
            }
            case 16: {
              return <EmployeeFeedbacksModel setstate={setState} setPopup={setPopup} empId={empId} dashboard={dashboard} />;
            }
            case 17: {
              return <EmployeeSubmissionsModel setstate={setState} setPopup={setPopup} empId={empId} dashboard={dashboard}/>;
            }
            case 18: {
              return <ServiceSubmissionInfoModel setstate={setState} id={id} setId={setId} setPopup={setPopup} tablename={tablename} />;
            }
            case 19: {
              return <UserRequestsTableModel setstate={setState} setPopup={setPopup} RequestserviceID={ RequestserviceID } />;
            }
            case 20: {
              return <ServiceRequestsTableModel setstate={setState} setPopup={setPopup} dashboard={dashboard} />;
            }
            case 21: {
              return <ServiceDetailsFeedbackModel setstate={setState} setPopup={setPopup} feedServiceId={feedServiceId} dashboard={dashboard} />;
            }
            case 22: {
              return <CompanySettingModel setstate={setState} setPopup={setPopup} />;
            }
            case 23: {
              return <SubmittedServicesModel setstate={setState} setPopup={setPopup} submissionsData={submissionsData} />;
            }
            case 24: {
              return <EmployeeHistoryModel setstate={setState} setPopup={setPopup} />;
            }
            case 25: {
              return <ServiceRequestInfoComModel setstate={setState} setPopup={setPopup} id={id} setId={setId} dashboard={dashboard} companyempid={companyempid} />
            }
            case 26: {
              return <SubmittedServicesInfoModel setstate={setState} setPopup={setPopup} tablename={tablename} />;
            }
            case 27: {
              return <EmpServiceHistoryModel setstate={setState} setPopup={setPopup} id={id} dashboard={dashboard} />;
            }
            case 28: {
              return <AddServiceModel setstate={setState} setPopup={setPopup} setNewServiceAdded={setNewServiceAdded}/>;
            }
            case 29: {
              return <ServicesSuccessModel  setstate={setState} setPopup={setPopup} setId={setId} handleSetup={handleSetup}/>;
            }
            case 30: {
              return <ServiceMenuModel  setstate={setState} setPopup={setPopup} id={id} setModel={setModel}/>;
            }
            case 31: {
              return <ServiceSetupMenuModel  setstate={setState} setPopup={setPopup} id={id}/>;
            }
            case 32: {
              return <SetupServiceModel setstate={setState} setPopup={setPopup} id={id} setId={setId} />;
            }
            case 33: {
              return <ServiceInfo setstate={setState} setPopup={setPopup}/>;
            }
            case 34: {
              return <ServicesActivateModel  setstate={setState} setPopup={setPopup} id={id}/>;
            }
            case 35: {
              return <ManageServiceModel  setstate={setState} setPopup={setPopup} id={id} activeTab={activeTab}/>;
            }
            case 36: {
              return <UpdateServiceName  setstate={setState} setPopup={setPopup} id={id} serviceName={serviceName} setServiceName={setServiceName}/>;
            }
            case 37: {
              return <UpdateServiceCategory  setstate={setState} setPopup={setPopup} id={id} serviceCategory={serviceCategory} setServiceCategory={setServiceCategory}/>;
            }
            case 38: {
              return <ManageServiceMenuModel  setstate={setState} setPopup={setPopup} id={id} userId={userId}/>;
            }
            case 39: {
              return <RemoveServiceMember  setstate={setState} setPopup={setPopup} id={id} userId={userId}/>;
            }
            case 40: {
              return <AddServiceMember  setstate={setState} setPopup={setPopup} id={id} userId={userId} />;
            }
            case 41: {
              return <ServiceMemberType  setstate={setState} setPopup={setPopup} id={id} userId={userId} />;
            }
            case 42: {
              return <UpdateServiceMemberType  setstate={setState} setPopup={setPopup} id={id} userId={userId} userType={userType}/>;
            }
            case 43: {
              return <UpdateUserServiceDetails id={id} setstate={setState} setPopup={setPopup}/>;
            }
            case 44: {
              return <UploadNewContract id={id} setstate={setState} setPopup={setPopup}/>;
            }
            case 45: {
              return <EmployeeMenuModel setstate={setState} setPopup={setPopup} setModel={setModel} userRole={userRole} empID={empID} empReport={empReport}/>;
            }
            case 46: {
              return <ChangeEmployeeRoleModel empID={empID} setstate={setState} setPopup={setPopup} userRole={userRole}/>;
            }
            case 47: {
              return <RemoveEmployeeModel empID={empID} setstate={setState} setPopup={setPopup}/>;
            }
            case 48: {
              return <DeactivateEmployeeModel empID={empID} setstate={setState} setPopup={setPopup} empReport={empReport}/>;
            }
            case 49: {
              return <UpdateEmployeeModel empID={empID} setstate={setState} setPopup={setPopup}/>;
            }
            case 50: {
              return <SimilarServices setstate={setState} setPopup={setPopup} state={state} popup={popup} similarServices={similarServices} userId={userId}/>;
            }
            case 51: {
              return <ManageContractModel setstate={setState} setPopup={setPopup} state={state} popup={popup} serviceId={serviceId} />;
            }
            case 52: {
              return <ConfirmModel setstate={setState}  setPopup={setPopup} id={id} state={state} popup={popup} setConfirmDelete={setConfirmDelete} setConfirmIgnore={setConfirmIgnore}/>;
            }
            case 53: {
              return <ManageDataScope setstate={setState} setPopup={setPopup} setId={setId} setModel={setModel} state={state} popup={popup} />;
            }
            case 54: {
              return <EditManageContractModel setstate={setState} setPopup={setPopup} state={state} popup={popup} id={id} />;
            }
            default:
              break;
          }
        })()}
      </Modal>
  );
};

export default BasicModal;