import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../../../../components/Buttons/button";
import { formatedAmount } from "../../../../utils/helpers";
import {
  createFeedbackRequest,
  getServicesRequest,
  selectPersonalDashboard,
  updatedServices,
} from "../../../../redux/slices/personalDashboardSlice";

import negativeIcon from "./../../../../assets/images/negative.png";
import negativeHoverIcon from "./../../../../assets/images/nagative_hover.png";
import neutralIcon from "./../../../../assets/images/neutral.png";
import neutralHoverIcon from "./../../../../assets/images/neutral_hover.png";
import smileyIcon from "./../../../../assets/images/smiley.png";
import smileyHoverIcon from "./../../../../assets/images/smiley_hover.png";

function PillCards({ setPopup, setState, data, modalId }) {
  // const [latestRating, setLatestRating] = useState(data?.latest_rating)
  const State = useSelector(selectPersonalDashboard);
  const { servicesData } = State;
  const dispatch = useDispatch();

  const currentServiceRate = servicesData?.services?.find(
    (el) => +el.id === +modalId
  )?.rating;

  const handleRatingClick = (newRating) => {
    dispatch(
      createFeedbackRequest({
        service_id: modalId,
        rating: newRating,
      })
    );

    const currentService = [...servicesData?.services].find(
      (el) => +el.id === +modalId
    );
    const servicesWithNewRating = [...servicesData?.services].map((el) =>
      el.id === currentService.id
        ? { ...currentService, rating: newRating }
        : el
    );

    dispatch(updatedServices(servicesWithNewRating));
  };

  useEffect(() => {
    dispatch(getServicesRequest());
  }, [dispatch]);

  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="flex flex-wrap gap-10">
        <div className="flex justify-between items-center gap-[12px] w-full max-w-[100%] h-[100px] sm:max-w-[calc(50%-20px)]  p-[24px] rounded-[32px] bg-[#363D50] custom-boxshadow">
          <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
            {data.monthly_cost && (
              <span className="text-primary !text-[18px]">{`$${formatedAmount(
                data.monthly_cost
              )}`}</span>
            )}
            {!data.monthly_cost && <Skeleton className="!w-[132px]" />}
            <span className="text-secondary !leading-[21px]">
              Your cost per month
            </span>
          </div>
          <Button
            onClick={() => {
              setPopup(true);
              setState(27);
            }}
            secondary={true}
            hoverColor={true}
          >
            History
          </Button>
        </div>

        <div className="flex justify-center flex-col w-full max-w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] sm:max-w-[calc(50%-20px)] rounded-[32px] bg-[#363D50] custom-boxshadow">
          <div className="flex w-[273px] gap-1">
            {currentServiceRate === "positive" ? (
              <img
                onClick={() => handleRatingClick("positive")}
                src={smileyHoverIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon active w-[28px]"
              />
            ) : (
              <img
                onClick={() => handleRatingClick("positive")}
                src={smileyIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon inactive w-[28px]"
              />
            )}
            {currentServiceRate === "general" ? (
              <img
                onClick={() => handleRatingClick("general")}
                src={neutralHoverIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon active w-[28px]"
              />
            ) : (
              <img
                onClick={() => handleRatingClick("general")}
                src={neutralIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon inactive w-[28px]"
              />
            )}
            {currentServiceRate === "negative" ? (
              <img
                onClick={() => handleRatingClick("negative")}
                src={negativeHoverIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon active w-[28px]"
              />
            ) : (
              <img
                onClick={() => handleRatingClick("negative")}
                src={negativeIcon}
                alt="rating-img"
                className="cursor-pointer rating_icon inactive w-[28px]"
              />
            )}
          </div>
          <span className="text-secondary !leading-[21px]">
            Your service rating
          </span>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;
