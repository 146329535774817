import React from 'react'
import ThryftSetting from './tabs/ThryftSetting'
import Members from './tabs/Members'
import About from './tabs/About'
import ManageData from './tabs/ManageData'

function Tabs({Tab, id}) {
  return (
    <div style={{ 
      position: 'absolute', 
      top: '16%', 
      left: '32%', 
  }}>
      {Tab === 1 && <About id={id} />}
      {Tab === 2 && <Members id={id}/>}
      {Tab === 4 && <ThryftSetting id={id}/>}
      {Tab === 3 && <ManageData id={id}/>}
    </div>
  )
}

export default Tabs