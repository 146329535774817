import React, { useState } from 'react';

function StatusBar({ percentage }) {
  const radius = 30;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="flex flex-col items-center">
      <svg
        height={radius * 2}
        width={radius * 2}
        className="transform rotate-[90deg]"
      >

      	<defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#6BE261', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#6BE26100', stopOpacity: 0 }} />
          </linearGradient>
        </defs>
        
        <circle
          // stroke="gray"
        	style={{ stroke: '#41485C' }}
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
	        <text
	          x="50%"
	          y="50%"
	          dx="2"
	          dy="2"
	          fill="#ffffff"
	          dominantBaseline="middle"
	          textAnchor="middle"
	          className="!text-[#ffffff] !text-[18px] !font-[700]"
	          style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%'}}
	        >
	          {percentage}
	          <tspan className="text-secondary !text-[10px] !font-[300]" fill="#someColor">%</tspan> 
	        </text>
        <circle
          stroke="url(#gradient)"
          // style={{ stroke:'conic-gradient(#6BE261 0%, rgba(107, 226, 97, 0) 100%)'}}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          className="transition-all duration-500 ease-out"
        />
      </svg>
    </div>
  );
}

export default StatusBar