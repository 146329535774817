import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../../../../../components/Buttons/button";
import { formatedAmount } from "../../../../../utils/helpers";
import negativeHoverIcon from "./../../../../../assets/images/nagative_hover.png";
import neutralHoverIcon from "./../../../../../assets/images/neutral_hover.png";
import smileyHoverIcon from "./../../../../../assets/images/smiley_hover.png";

function PillCards({ setPopup, setState, data, setRequestId }) {
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="flex justify-center xl:justify-between items-center gap-[40px]">
        <div className="flex items-center justify-between h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px] w-1/2">
          <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
            {data?.monthly_cost && (
              <span className="text-primary !text-[18px]">
                {`$${formatedAmount(data.monthly_cost)}`}
              </span>
            )}
            {data?.monthly_cost === undefined && (
              <Skeleton className="!w-[132px]" />
            )}
            <span className="text-secondary !leading-[21px]">
              User cost per month
            </span>
          </div>
          <Button
            onClick={() => {
              setPopup(true);
              setState(27);
              setRequestId(data.service.id);
            }}
            secondary={true}
            hoverColor={true}
          >
            History
          </Button>
        </div>

        <div className="flex flex-col justify-center w-1/2 h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]">
          <div className="flex w-[273px] gap-[16px]">
            {data?.latest_rating === "positive" ? (
              <>
                <img
                  src={smileyHoverIcon}
                  alt="rating-img"
                  className="rating_icon active w-[32px]"
                />
              </>
            ) : null}

            {data?.latest_rating === "general" ? (
              <>
                <img
                  src={neutralHoverIcon}
                  alt="rating-img"
                  className="rating_icon active w-[32px]"
                />
              </>
            ) : null}

            {data?.latest_rating === "negative" ? (
              <>
                <img
                  src={negativeHoverIcon}
                  alt="rating-img"
                  className="rating_icon active w-[32px]"
                />
              </>
            ) : null}
          </div>
          <span className="text-secondary !leading-[21px]">Impact rating</span>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;
