import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/Buttons/button";
import StatusBar from "../../../../../components/statusBar/StatusBar";

function formatDate(date, includeDay) {
  return date
    ? new Date(date).toLocaleDateString(undefined, {
        month: "short",
        ...(includeDay && { day: "numeric" }),
        year: "numeric",
      })
    : "";
}

function Header({ data, show, setShow, setState, setPopup, dataScorePercentage, upcomingReportMonth }) { 

  const handleDataScore = () => {
    setPopup(true)
    setState(53)
  }

    const getLastMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    return new Date(today.getFullYear(), lastMonth).toLocaleDateString('en-US', { month: 'short' });
  };

  const dataScoreMonth = getLastMonth();

  return (
    <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[12px] pt-[24px]">
      <div className="flex flex-col gap-[24px] lg:flex-row lg:justify-between items-center">
        <div className="flex gap-[10px]">
          <div
            onClick={() => {
              setShow(!show);
            }}
            className="xl:hidden 2xl:hidden"
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 18C7 18.5523 7.44772 19 8 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H8C7.44772 17 7 17.4477 7 18ZM7 12C7 12.5523 7.44772 13 8 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H8C7.44772 11 7 11.4477 7 12ZM8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H8Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex flex-col">
            <span className="text-primary !text-[32px] !leading-[30px] lg:!leading-[40px] xl:!leading-[48px]">
              Home
            </span>
            <span className="text-secondary">
              Updated: {formatDate(data.last_updated_date, true)}
            </span>
          </div>
        </div>

        <div className="h-[88px]"></div>

        <button onClick={() => handleDataScore()}>
          <div className="flex justify-end items-center">
          <div className="bg-[#242B3E] rounded-[32px] py-[8px] pl-[14px] pr-[24px] flex flex-col hover:bg-[#41485C]">
            <div className="flex items-center justify-between gap-2">
              <div>
                <StatusBar percentage={dataScorePercentage} />
              </div>
              <div className="flex flex-col pt-2">
                <div className="text-primary !text-[14px] !leading-[14px] lg:!leading-[14px] xl:!leading-[14px] text-[#FFFFFF]">
                  Data score ({dataScoreMonth})
                </div>
                <span className="text-secondary text-left hover:text-[#FFD74A] underline"> manage </span>
              </div>
            </div>
          </div>
          </div>
        </button>

        {/*<div className='flex justify-end'>
            <Button outlined={true} hoverColor={true} secondary={true} secondaryHoverOutlined={true}>
                Company Details
            </Button>
        </div>*/}
      </div>
      <div className="bg-[#41485C] w-[100%] h-[1px]" />
    </header>
  );
}

export default Header;
