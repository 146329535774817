import React, { useState, useEffect } from 'react'
import Button from "../../../../../components/Buttons/button";
import DataScoreCard from "../../../../../components/data-score-card/DataScoreCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  companyPublishReports,
  companyPublishMonthlyReports,
  getManageServicesDataScore,
  selectCompanyDashboard,
  UploadCompanyIcon,
  UpdateOrgCostVisibility,
  getOrgCostVisibility
} from "../../../../../redux/slices/companyDashboardSlice";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { motion, AnimatePresence } from "framer-motion"
import BasicModal from "../../../../models/models";


const OrgSetting = ({setModel}) => { 
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [id, setId] = useState("");
  const [monthlyReport, setMonthlyReport] = useState(false)
  const [visibleCost, setVisibleCost] = useState(false)
  const [startDay, setStartDay] = useState(new Date());
  const [startTime, setStartTime] = useState(
    // new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase()
  );
  const [autoNotification, setAutoNotification] = useState(false)
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { manageServicesData, orgCostVisibility, publishCompanyReport, UserServiceData } = State;
  const UserState = useSelector(selectUser);
  const { userDetails } = UserState;
  const reportTime = manageServicesData.monthly_report_time
  const reportDate = manageServicesData.monthly_report_date
  const service_ids = manageServicesData.service_id_without_USD
  const LastGeneration = manageServicesData.last_report_time
  const monthlyReportData = manageServicesData.monthly_report_publishing
  const autoNotifyData = manageServicesData.auto_notification

  useEffect(()=>{
    if (reportDate){
      const newDate = new Date(reportDate)
      setStartDay(newDate)
      setStartTime(newDate)
    }
    if (monthlyReportData){
      setMonthlyReport(monthlyReportData)
    }
    if (autoNotifyData){
      setAutoNotification(autoNotifyData)
    }

  },[reportDate, monthlyReportData, autoNotifyData])

  useEffect(()=>{
    if(orgCostVisibility){
      setVisibleCost(orgCostVisibility?.cost)
    }
  },[orgCostVisibility?.cost])

  const handlePublishReport = () => {
    dispatch(companyPublishReports());
  }

  const HandleMonthlyReport = (e) => {
    setMonthlyReport(e.target.checked);
    dispatch(companyPublishMonthlyReports({ monthlyReport: e.target.checked }));
  };

  const HandleAutoNotification = (e) => {
    setAutoNotification(e.target.checked)
    dispatch(companyPublishMonthlyReports({ autoNotification: e.target.checked }));
  }

  const handleDayChange = (date) => {
    setStartDay(date)
    dispatch(companyPublishMonthlyReports({ startDay: date }));
  }

  const handleTimeChange = (date) => {
    setStartTime(date)
    dispatch(companyPublishMonthlyReports({ startTime: date }));
  }

  const companyIconeUpload = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    if(file){
      dispatch(UploadCompanyIcon(file))
    }
  }

  useEffect(()=>{
    dispatch(getManageServicesDataScore())
  },[dispatch, publishCompanyReport]);


  const handleDataScore = () => {
    setState(53)
    setPopup(true)
  }

  useEffect(()=>{
    dispatch(getOrgCostVisibility())
  },[visibleCost]);

  const handleCostVisibility = (e) =>{
    const checkedValue = e.target.checked
    setVisibleCost(checkedValue)
    dispatch(UpdateOrgCostVisibility(checkedValue))
  }

  return (

    <div className='p-8 flex flex-col items-baseline'>
      <div className=" flex flex-col w-full py-[24px] border-b-[1px] border-[#434A5D]">
        <div className='text-primary font-[700] !text-[24px] text-left'>
          {userDetails?.user?.company_name} settings
        </div>
        <div className=" flex flex-col items-end">
          <Button
            outlined={true}
            hoverColor={true}
            secondary={false}
            secondaryHoverOutlined={true}
            fill={true}
          >
            <label className="flex cursor-pointer items-center gap-1">
              <input
                hidden
                type="file"
                id="company_icon"
                name="company"
                accept=".png .jpg .jpeg"
                onChange={(e)=>companyIconeUpload(e) }
              />
              Upload company icon
            </label>
          </Button>
          <p className="text-secondary text-left">Please upload a .png or .jpg file </p>
        </div>
      </div>
      <div className="max-h-[500px] overflow-y-scroll scrollbar-hide">
        <div className="border-b-[1px] border-[#434A5D] py-[24px] w-full px-[5px]">
        <div className="flex justify-between items-center">
          <div className="text-primary font-[700] text-[16px] text-left">Reports</div>
          <div className="flex gap-4 items-center">
            <div className="flex flex-col items-end">
              <div className="text-secondary">Last generation:</div>
              <div className="text-secondary">{ LastGeneration ? `${new Date(LastGeneration).toLocaleDateString('en-GB')}` : null }</div>
            </div>
            <div className="">
              <Button
                onClick={()=>handlePublishReport()}
                className="btnHover bg-gray-700 btnText"
                outlined
              >
                Publish reports now
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-4 pt-[15px]">
            <input type='checkbox' checked={monthlyReport} onChange={HandleMonthlyReport} className="border-[2px] border-[#ffffff] rounded-[2px] text-[#FFD74A] checked:bg-[#00000] focus:border-0 focus:ring-0 bg-[rgba(255,255,255,0)] mt-[7px] cursor-pointer" />
            <div className="flex flex-col flex-start items-baseline">
              <div className="text-primary !font-[500] !text-[14px] leading-none text-left">Automatically publish all reports monthly</div>
              <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">Thryft will automatically publish new reports for each period using available service data. The relevant reports will be visible to all users.</div>
            </div>
          </div>
          <AnimatePresence>
            { monthlyReport &&
            <motion.div
              classNmae="flex flex-col"
              initial={{ opacity: 0, height: 0 }}
              animate={ monthlyReport ? { opacity: 1, height: 'auto' }: { opacity: 0, height: 0 }}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              // style={{ overflow: 'hidden'}}
              exit={{ opacity: 0, height: 0 }}
            >
              <div className="flex items-center pl-[33px] gap-4 py-[15px]">
                <div>
                  <div className="orgSetting-day">
                    <div className="flex flex-col MonthPickerWrap relative">
                      <div className="absolute top-[12px] left-[16px]">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13 3V1M13 3V5M13 3H8.5M1 9V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V9M1 9H19M1 9V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H5M19 9V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H16.5M5 1V5" stroke="#A5AEBC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      <DatePicker
                        selected={startDay}
                        onChange={(date) => handleDayChange(date)}
                        placeholderText="Select day"
                        dateFormat="d"
                        showMonthDropdown={false}
                        showYearDropdown={false}
                        showPopperArrow={false}
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                          <div className="flex justify-between">
                            <button onClick={decreaseMonth}>{"<"}</button>
                            <span>{date.toLocaleString("default", { month: "long" })}</span>
                            <button onClick={increaseMonth}>{">"}</button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-secondary"> at </div>
                <div>
                  <div className="orgSetting-time">
                    <div className="flex flex-col MonthPickerWrap relative">
                      <div className="absolute top-[12px] left-[16px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61306 20.0518 7.32387 18.364 5.63604C16.6761 3.94822 14.387 3 12 3Z" fill="#A5AEBC"/>
                          <path d="M15.4425 16.5L11.25 12.3075V5.25H12.75V11.685L16.5 15.4425L15.4425 16.5Z" fill="#A5AEBC"/>
                        </svg>
                      </div>
                      <DatePicker
                        selected={startTime}
                        onChange={(date) => handleTimeChange(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        placeholderText="Select time"
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className=""
                        // popperClassName="!w-full !b-[#293043] text-[#fffff]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 pt-[15px] pl-[33px]">
                <input type='checkbox' checked={autoNotification} onChange={HandleAutoNotification} className="border-[2px] border-[#ffffff] rounded-[2px] text-[#FFD74A] checked:bg-[#00000] focus:border-0 focus:ring-0 bg-[rgba(255,255,255,0)] mt-[7px] cursor-pointer" />
                <div className="flex flex-col flex-start items-baseline">
                  <div className="text-primary !font-[500] !text-[14px] leading-none text-left">Automatically notify service manager owners</div>
                  <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">Thryft will send an email to service owners 10 days before the selected generation date and time, reminding them to upload service usage data.</div>
                </div>
              </div>
            </motion.div>
            }
          </AnimatePresence>
          <div
            className="pt-[20px] cursor-pointer"
            onClick={()=>handleDataScore()}
          >
            <DataScoreCard
              manageServicesData={manageServicesData}
              service_ids={service_ids}
            />
          </div>
        </div>
        </div>
        <div className="border-b-[1px] border-[#434A5D] py-[24px] w-full px-[5px]">
        <div className="text-primary font-[700] text-[16px] text-left">Rules</div>
        <div className="flex gap-4 pt-[15px]">
          <input type='checkbox' checked={visibleCost} onChange={handleCostVisibility} className="border-[2px] border-[#ffffff] rounded-[2px] text-[#FFD74A] checked:bg-[#00000] focus:border-0 focus:ring-0 bg-[rgba(255,255,255,0)] cursor-pointer" />
          <div className="flex flex-col flex-start items-baseline">
            <div className="text-primary !font-[500] !text-[14px] leading-none text-left">Total cost visability</div>
             <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">Employees can view cost totals when accessing reports from their employee dashboards.</div>
          </div>
        </div>
        </div>
      </div>

      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          // manageServicesData={manageServicesData}
          setModel={setModel}
          id={id}
          setId={setId}
        />
      )}

    </div>
  )
}

export default OrgSetting;
