import React, { useState, useEffect} from "react";
import Button from '../../../../../components/Buttons/button';
import copyIcon from "../../../../../assets/images/copy.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, selectUser } from "../../../../../redux/slices/userSlice";
import { getSetupServicesData, setServiceUpdatedFalse, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import BasicModal from "../../../../models/models";

const About = ({id}) => {
  const dispatch = useDispatch();
  const companyDashboardState  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData, serviceUpdated} = companyDashboardState
  const setupServices = SetServicesData?.setup_services;
  const userState = useSelector(selectUser);
  const {userDetails} = userState
  const user = userDetails?.user
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [serviceName,setServiceName] = useState("")
  const [serviceCategory,setServiceCategory] = useState("")
  const [copyStatus,setCopyStatus] = useState("")
  const created = SetServicesData?.service?.created_at
    ? new Date(SetServicesData?.service?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    : null;

  useEffect(()=>{
    dispatch(getSetupServicesData(id))
  },[id,serviceUpdated])

  useEffect(()=>{
    setServiceName(setupServices?.name)
  },[setupServices])

  const handleServiceName =(serviceId) =>{
    setPopup(true);
    setState(36);
  }

  const handleServiceCategory =(serviceId) =>{
    setPopup(true);
    setState(37);
    // setId(serviceId);
  }

  const copyServiceId = () => {
    navigator.clipboard.writeText(id)
    .then(()=>{
      setCopyStatus("Thryft service ID Copied");
      setTimeout(() => setCopyStatus(""), 800);
    })
    .catch(err => {
      setCopyStatus("Failed to copy Thryft service ID");
      setTimeout(() => setCopyStatus(""), 800);
    });
  };


  return (
    <div className='manage-service-wrapper'>
      <div className="text-primary !text-[24px] !leading-[24px] text-left">About</div>
      <div className="serviceSection flex justify-between w-full mt-4 p-[24px] rounded-[32px] flex-col">
        <div className="serviceRow flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span className="text-secondary !text-[#FFFFFF] !text-left serviceName fontBold">Service name</span>
            <span className="text-secondary !text-left serviceName">{setupServices?.name}</span>
          </div>
          <Button
            secondary={true}
            hoverColor={true}
            onClick={()=> {
              handleServiceName(setupServices?.id)
              dispatch(setServiceUpdatedFalse())
            }}          >
            Edit
          </Button>
        </div>
        <div className="serviceRow flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span className="text-secondary !text-[#FFFFFF] !text-left serviceName fontBold">Service category</span>
            <span className="text-secondary !text-left serviceName">{setupServices?.category}</span>
          </div>
          <Button
            secondary={true}
            hoverColor={true}
            onClick={()=> {
              handleServiceCategory(setupServices?.id)
              dispatch(setServiceUpdatedFalse())
            }}
          >
            Edit
          </Button>
        </div>
      </div>

      <div className="serviceSection flex justify-between w-full mt-8 p-[24px] rounded-[32px] flex-col">
        <div className="serviceRow flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span className="text-secondary !text-[#FFFFFF] !text-left serviceName fontBold">Added by</span>
            <span className="text-secondary !text-left serviceName">{user?.name} on {created}</span>
            <span className="text-secondary !text-left serviceName">{user?.email}</span>
          </div>
        </div>
        <div className="serviceRow flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span className="text-secondary !text-[#FFFFFF] !text-left serviceName fontBold">Procurement contact</span>
            <span className="text-secondary !text-left serviceName">{setupServices?.poc_name}</span>
          </div>
      
        </div>
        <div className="serviceRow flex justify-between items-center w-full">
          <div className="flex flex-col">
            <span className="text-secondary !text-[#FFFFFF] !text-left serviceName fontBold">Thryft service ID</span>
            <span className="text-secondary !text-left serviceName">{setupServices?.id}</span>
          </div>
          <Button
            secondary={true}
            hoverColor={true}
            onClick={copyServiceId}
          >
            {copyStatus && (
              <div className="mr-2 text-sm">
                {copyStatus}
              </div>
            )}
            <img src={copyIcon} alt="icon" className="copyIcon" />
          </Button>
        </div>
      </div>
      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          id={id}
          serviceName={serviceName}
          setServiceName={setServiceName}
          serviceCategory={serviceCategory}
          setServiceCategory={setServiceCategory}
        />
      )}

    </div>
  )
}

export default About