import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../../components/Buttons/button";
import Search from "../../../../../assets/images/search.png";
import menuDot from "../../../../../assets/images/menuDot.png";
import BasicModal from "../../../../models/models";
import { getAuthorizedServices, getAll_employees, setServiceCreatedFalse, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { getUserDetails, selectUser } from "../../../../../redux/slices/userSlice";
import Pill from "../../../../Pill/pill";
import Unicon from "../../../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { getInitials } from '../../../../../shared/lib/getInitials';
import { getRandomHex } from '../../../../../shared/lib/getRandomHex';

const ManageEmployees = ({setModel}) => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const [id, setId] = useState("")
  const [state, setState] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [newServiceAdded, setNewServiceAdded] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [hexColor, hexWithOpacity] = getRandomHex();
  const State = useSelector(selectCompanyDashboard);
  const { allEmployees, serviceUpdated, userMenuUpdated } = State;
  const userState = useSelector(selectUser);
  const { userDetails } = userState
  const adminRole = userDetails.user.role
  const [userRole, setUserRole] = useState('')
  const [empID,setEmpID] = useState(0)
  const [empReport,setEmpReport] = useState('')
  const employees = allEmployees?.all_employee

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const service_id = params.get('service_id');

  // useEffect(()=>{
  //   if(service_id){
  //     setPopup(true);
  //     setState(32);
  //     setId(service_id);
  //   }
  // },[service_id])

  useEffect(() => {
    dispatch(getAll_employees());
  }, [dispatch, serviceUpdated, state, userMenuUpdated]);

  useEffect(() => {
    if (employees) {
      setSortedUsers([...employees]);
    }
  }, [employees]);

  const displayUsers = sortedUsers.filter((user) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameMatch = user.name?.toLowerCase().includes(searchTermLower) || false;
    const emailMatch = user.email?.toLowerCase().includes(searchTermLower) || false;
    const employeeIdMatch = user.id?.toString().toLowerCase().includes(searchTermLower) || false;

    return nameMatch || emailMatch || employeeIdMatch;
  });

  const handleMenuClick = (userId, role, report) => {
    setPopup(true);
    setState(45);
    setUserRole(role);
    setEmpID(userId);
    setEmpReport(report);
  }

  // useEffect(() => {
  //   if (newServiceAdded && newServiceAdded) {
  //     dispatch(getAuthorizedServices());
  //     dispatch(getInitializeServicesData());
  //     setNewServiceAdded(false);
  //     setState(29);
  //   }
  // }, [newServiceAdded]);

  const handleSortByName = () => {
    const sorted = [...employees].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };


  const handleRole = () => {
    const sorted = [...employees].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.role.localeCompare(b.role);
      } else {
        return b.role.localeCompare(a.role);
      }
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const handleDataReporting = () => {
    const sorted = [...employees].sort((a, b) => {
      if (sortOrder === "asc") {
        return (a.user_access === b.user_access) ? 0 : a.user_access ? 1 : -1;
      } else {
        return (a.user_access === b.user_access) ? 0 : a.user_access ? -1 : 1;
      }
    });

    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const handleAdded = () => {
    const sorted = [...employees].sort((a, b) => {
      const dateA = parseDate(a.added);
      const dateB = parseDate(b.added);
      if (sortOrder === "asc") {
          return dateA - dateB;
      } else {
          return dateB - dateA;
      }
    });

    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };


  return (
    <div className="px-4 py-8 ManageServicesBox">
      <div className="headingBox !px-4 flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold">Manage Employees</h2>
        <div className="flex flex-row gap-[24px]">
          <div className="flex flex-col items-end">
            <span className="text-secondary">
              integration data:
            </span>
            <span className="text-secondary !italic">
              integration name(status)
            </span>
          </div>
          {(adminRole === "admin") && (
            <Button
              onClick={() => {
                setPopup(true);
                setState(49);
                // dispatch(setServiceCreatedFalse());
              }}
              outlined={true}
              hoverColor={true}
              secondary={true}
              secondaryHoverOutlined={true}
            >
              Update Employees
            </Button>
          )}
        </div>
      </div>

      <div className="searchBox !mx-4">
        <img src={Search} alt="icon" className="searchIcon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by name, email, or employee ID"
          className="searchInput"
        />
      </div>

      <div className="rounded-t-xl !mt-0 w-[100%] scrollbar-hide scrollable_data">
        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
          <table className="sticky top-0">
            <thead className="table-head sticky top-0 bg-[#363D50]">
              <tr>
                <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px] w-[400px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleSortByName}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Name
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleRole}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Role
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleDataReporting}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      User Access
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleAdded}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Added
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[12px] px-[12px]`} >&nbsp;</th>
              </tr>
            </thead>

            <tbody>
             {displayUsers?.map((user) => (
              <tr key={user.id} className="hover:bg-[#363D50] hover:brightness-110 ">
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[16px]"
                >
                  <div className="flex flex-col md:flex-row items-center gap-[10px]">
                    <div
                      style={{
                        backgroundColor: hexWithOpacity,
                        color: hexColor,
                      }}
                      className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                    >
                      {getInitials(user.name)}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                        {user.name}
                      </span>
                      <span className="text-secondary lineHeight !text-left">
                        {user.distinction}
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium font_12px"
                >
                  {user.role}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium font_12px"
                >
                  {/* <div>{user.reporting_status}</div> */}
                  {user.user_access === true ? (
                    <Pill greenFill={true}> Active </Pill>
                  ) : (
                    <Pill redFill={true}>Deactivated </Pill>
                  )}
                </td>
                <td
                  className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium"
                >
                  {user?.added}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[13.5px] px-[28px] !font-medium"
                >
                  <div
                    className="menuDot cursor-pointer"
                    onClick={() => handleMenuClick(user.id, user.role, user.user_access)}
                  >
                    <img src={menuDot} alt="icon" />
                  </div>

                </td>
              </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          empID={empID}
          userRole={userRole}
          setModel={setModel}
          empReport={empReport}
        />
      )}
    </div>
  )
}

export default ManageEmployees