import React from 'react'
import StatusBar from "../statusBar/StatusBar";
import { useDispatch, useSelector } from 'react-redux';
import {
	notifyMultipleServiceOwner,
  selectCompanyDashboard,
} from "../../redux/slices/companyDashboardSlice";

const DataScoreCard = ({manageServicesData, service_ids}) => {
	const dispatch = useDispatch();
  const percentage = manageServicesData.data_score_percentage
  const services = manageServicesData.services
  const totalUserServiceDetails = manageServicesData.total_contracts
  const completeUserServiceDetails = manageServicesData.services_with_user_details_count
  const missing = totalUserServiceDetails - completeUserServiceDetails
  const reportDay = manageServicesData.monthly_report_day
  const upcomingReportMonth = manageServicesData.upcoming_report_month
	const currentDate = new Date();
	const currentDay = currentDate.getDate();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

  const calculateRemainingDays = (reportDay, currentDay) => {
    let remainingDays = reportDay - currentDay;

    if (remainingDays < 0) {
      const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      remainingDays = (daysInCurrentMonth - currentDay) + Number(reportDay);
    }

    return remainingDays;
  };

  const remainingDays = calculateRemainingDays(reportDay, currentDay);
  // const dataScoreMonth = new Date(manageServicesData?.last_report_time).toLocaleDateString('en-US', { month: 'short' })
  const getLastMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    return new Date(today.getFullYear(), lastMonth).toLocaleDateString('en-US', { month: 'short' });
  };

	const dataScoreMonth = getLastMonth();

  const handleClick = () => {
		dispatch(notifyMultipleServiceOwner(service_ids))
  }

  return (
    <div className='flex flex-col bg-[#293043] rounded-[32px] px-[16px] py-[16px]'>
    	<div className="!flex !justify-between !items-center">
    		<div>
    			<div className="flex items-center gap-2">
    				<StatusBar percentage={percentage} />
    				<div className="flex flex-col">
							<div className="text-primary text-left">Data score ({dataScoreMonth})</div>
    					<div className=" flex items-center text-secondary text-left gap-1">
    						<span>
	    						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.99968 10.334C7.18856 10.334 7.34701 10.27 7.47501 10.142C7.60301 10.014 7.66679 9.85576 7.66634 9.66732C7.6659 9.47887 7.6019 9.32065 7.47434 9.19265C7.34679 9.06465 7.18856 9.00065 6.99968 9.00065C6.81079 9.00065 6.65256 9.06465 6.52501 9.19265C6.39745 9.32065 6.33345 9.47887 6.33301 9.66732C6.33256 9.85576 6.39656 10.0142 6.52501 10.1427C6.65345 10.2711 6.81168 10.3349 6.99968 10.334ZM6.99968 7.66732C7.18856 7.66732 7.34701 7.60332 7.47501 7.47532C7.60301 7.34732 7.66679 7.1891 7.66634 7.00065V4.33398C7.66634 4.1451 7.60234 3.98687 7.47434 3.85932C7.34634 3.73176 7.18812 3.66776 6.99968 3.66732C6.81123 3.66687 6.65301 3.73087 6.52501 3.85932C6.39701 3.98776 6.33301 4.14598 6.33301 4.33398V7.00065C6.33301 7.18954 6.39701 7.34798 6.52501 7.47598C6.65301 7.60398 6.81123 7.66776 6.99968 7.66732ZM6.99968 13.6673C6.07745 13.6673 5.21079 13.4922 4.39968 13.142C3.58856 12.7918 2.88301 12.3169 2.28301 11.7173C1.68301 11.1178 1.20812 10.4122 0.858342 9.60065C0.508564 8.7891 0.333453 7.92243 0.333009 7.00065C0.332564 6.07887 0.507675 5.21221 0.858342 4.40065C1.20901 3.5891 1.6839 2.88354 2.28301 2.28398C2.88212 1.68443 3.58768 1.20954 4.39968 0.859318C5.21168 0.509096 6.07834 0.333984 6.99968 0.333984C7.92101 0.333984 8.78767 0.509096 9.59968 0.859318C10.4117 1.20954 11.1172 1.68443 11.7163 2.28398C12.3155 2.88354 12.7906 3.5891 13.1417 4.40065C13.4928 5.21221 13.6677 6.07887 13.6663 7.00065C13.665 7.92243 13.4899 8.7891 13.141 9.60065C12.7921 10.4122 12.3172 11.1178 11.7163 11.7173C11.1155 12.3169 10.4099 12.792 9.59968 13.1427C8.78945 13.4933 7.92279 13.6682 6.99968 13.6673Z" fill="#ED4650"/>
									</svg>
								</span>
								{remainingDays} days until report generation
							</div>
    				</div>
    			</div>
    		</div>
    		<div>
	    		<div className="flex items-center gap-4">
	    			{ manageServicesData.data_score_percentage === 100 ? null :
		    			<div className="flex flex-col items-end">
		    				<div className="text-primary !text-[14px]">
									<span className="!text-[#ED4650] !text-[18px]">{missing}</span>
										/ {totalUserServiceDetails}
		    				</div>
		    				<div className="text-secondary">missing data instances</div>
		    			</div>
	    			}
	    			<div>
							<button onClick={()=>handleClick()}>
	    					<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="52" height="52" rx="16" fill="#363D50"/>
									<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3681 16.6489C17.9535 15.978 16.3514 17.1207 16.5244 18.6768L17.2638 25.3332H26.0005C26.3687 25.3332 26.6671 25.6316 26.6671 25.9998C26.6671 26.368 26.3687 26.6665 26.0005 26.6665H17.2638L16.5244 33.3241C16.3515 34.8795 17.953 36.022 19.3683 35.352L35.2937 27.8067C36.8185 27.0848 36.8187 24.9149 35.2939 24.193L19.3681 16.6489ZM15.9964 25.9998L15.1992 18.8242C14.9109 16.231 17.5806 14.3257 19.9392 15.444M19.9392 15.444L35.8644 22.9879C38.4061 24.1914 38.4062 27.8081 35.8646 29.0116L19.9392 36.557C17.5813 37.6734 14.9109 35.7707 15.1992 33.1769L15.9964 25.9998" fill="#FFD74A"/>
								</svg>
	    				</button>
	    			</div>
	    		</div>
	    	</div>
    	</div>
    </div>
  )
}

export default DataScoreCard