import { createSlice } from '@reduxjs/toolkit';
import { fetchWithToken, postWithToken, patchWithToken, deleteWithToken, postRequest, patchRequest } from '../../config/apiRequests';
import { toast } from "react-toastify";
import SuccessIcon from "../../assets/icons/success-check.svg";
// import { updateRequestDetails } from './personalDashboardSlice'

export const companyDashboardSlice = createSlice({
  name: 'companyDashboard',
  initialState: {
    isLoading: false,
    success: false,
    message: '',
    endPoint: '',
    homeData: {},
    usageReportData: {},
    submissionsData: {},
    SubmissionsListData:[],
    employeeData: undefined,
    employeesData: undefined,
    managerName: '',
    servicesData: {},
    newServicesData: {},
    requestsData: {},
    CompanyhistoryData: {},
    usageReportFeedbacksData: {},
    specficFeedbackData: {},
    usageReportRequestData: {},
    usageReportServiceData: {},
    employeeRequestData: {},
    employeeSubmissionData: {},
    empSubmissionDetail: {},
    employeefeedbackData: {},
    requestDetailsData: {},
    employeefeedbacksingle: {},
    usagecompanyReportData: {},
    empServiceHistoryData: {},
    companySubmissionDetailData:{},
    authorizedServicesData:{},
    CategoriesAndUsersData:{},
    allEmployees:{},
    downloadFileData:{},
    serviceCreated: false,
    serviceUpdated: false,
    serviceMemberUpdated: false,
    serviceMemberCreated: false,
    serviceMenuUpdated: false,
    userMenuUpdated: false,
    serviceMemberData:{},
    template:{},
    employee_template:{},
    savingsOpportunityData:{},
    YearlyUsegeServiceData:{},
    ServiceContractData:{},
    contractData:{},
    UserServiceData: false,
    createContract: false,
    manageServicesData:{},
    serviceCostVisibility: {},
    orgCostVisibility: {},
    publishCompanyReport: {}
  },

  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    updateData: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.success = true;
        state.message = `${action.payload.type} fetched successfully`;
        state[action.payload.type] = action.payload.data;
      } else {
        state.success = false;
      }
    },
    deleteManagerName: (state) => {
      state.managerName = "";
    },

    updateEmployees: (state, action) => {
      if (action.payload) {
        state.success = true
        state.message = "Company employees fetched successfully";
        state.employeesData = action.payload.employees;
        state.managerName = action.payload.manager_name;
      } else {
        state.success = false
      }
    },

    getUsegesServiceData: (state, action) => {
      if (action.payload) {
        state.success = true
        // state.message = "Company employees fetched successfully";
        state.YearlyUsegeServiceData = action.payload;
      } else {
        state.success = false
      }
    },

    getServiceContractData: (state, action) => {
      if (action.payload) {
        state.success = true
        // state.message = "Company employees fetched successfully";
        state.ServiceContractData = action.payload;
      } else {
        state.success = false
      }
    },

    getContractdata: (state, action) => {
      if (action.payload) {
        state.success = true
        state.contractData = action.payload;
      } else {
        state.success = false
      }
    },

    notifyMultiServiceOwner: (state,action) => {
      if (action.payload) {
        state.success = true
        state.message = "Email sent  successfully";
        toast.success(state.message, {
          icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
        });
        // state.ServiceContractData = action.payload;
      } else {
        state.success = false
      }
    },

    notifyServiceOwner: (state,action) => {
      if (action.payload) {
        state.success = true
        state.message = "Email sent successfully";
        toast.success(state.message, {
          icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
        });
        // state.ServiceContractData = action.payload;
      } else {
        state.success = false
      }
    },

    notifyPublishReport: (state,action) => {
      if (action.payload) {
        state.success = true
        state.message = "Report publish successfully";
        state.publishCompanyReport = action.payload.data
        toast.success(state.message, {
          icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
        });
        // state.ServiceContractData = action.payload;
      } else {
        state.success = false
      }
    },

    notifyPublishMonthlyReport: (state,action) => {
      if (action.payload) {
        state.success = true
        // state.message = "Report publish successfully";  
      } else {
        state.success = false
      }
    },


    updateSortedEmployees: (state, action) => {
      if (action.payload) {
        state.success = true
        state.message = "Company employees fetched successfully";
        state.employeesData = action.payload.employees.sort(
          (a, b) => b.monthly_cost - a.monthly_cost
        );
        state.managerName = action.payload.manager_name;
      } else {
        state.success = false
      }
    },

    savingsOpportunities: (state, action) => {
      if (action.payload) {
        state.success = true
        state.savingsOpportunityData = action.payload;
      } else {
        state.success = false
      }
    },

    manageServicesDataScore: (state, action) => {
      if (action.payload) {
        state.manageServicesData = action.payload
      } else {
        state.success = false
      }
    },

    employeeRequests: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:id/requests";
        state.message = "Requests fetched successfully";
        state.employeeRequestData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/requests";
        state.isLoading = false;
      }
    },
    serviceMember: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:id/requests";
        state.message = "Requests fetched successfully";
        state.serviceMemberData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/requests";
        state.isLoading = false;
      }
    },

    ServiceTemplate: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/template/${id}";
        state.message = "Requests fetched successfully";
        state.template = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/template/${id}";
        state.isLoading = false;
      }
    },

    EmployeeTemplate: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/employee_template";
        state.message = "Requests fetched successfully";
        state.employee_template = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/employee_template";
        state.isLoading = false;
      }
    },

    createRequestComment: (state, action) => {
      const { status, id, ...payload } = action.payload;
      state.endPoint = "api/v1/personal/requests/{id}/comment";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request comment created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.requestCommentData = {
            ...state.requestCommentData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Request comment creation failed";
          toast.error(state.message);
      }
    },
    updaterequestData: (state, action) => {
      const { status, id, ...payload } = action.payload;

      state.endPoint = "api/v1/personal/requests/:id";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request updated successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.requestDetailsData = {
            ...state.requestDetailsData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Update Request failed";
          toast.error(state.message);
      }
    },
    createService: (state, action) => {
      const { status, id, data, ...payload } = action.payload;
      state.endPoint = "api/v1/add_services";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Service created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.newServicesData = data;
          state.serviceCreated = true;
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Service creation failed";
          toast.error(state.message);
      }
    },
    serviceUseges: (state, action) => {
      const { status, ...payload } = action.payload;
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Service usage created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.serviceUsagesData = {
            ...state.serviceUsagesData,
            ...payload,
          };
          break;

        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;

        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;

        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;

        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;

        default:
          state.success = false;
          state.message = "Service usage creation failed";
          toast.error(state.message);
          break;
      }
    },
    contractData: (state, action) => {
      const { status, ...payload } = action.payload;
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Contract data created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.createContract = true
          break;

        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;

        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;

        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;

        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;

        default:
          state.success = false;
          state.message = "Contract Data creation failed";
          toast.error(state.message);
          break;
      }
    },
    employeeFileData: (state, action) => {
      const { status, ...payload } = action.payload;
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Employee data uploaded";
          state.isLoading = false;
          state.userMenuUpdated = true;
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          // state.serviceUsagesData = {
          //   ...state.serviceUsagesData,
          //   ...payload,
          // };
          break;

        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;

        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;

        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;

        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;

        default:
          state.success = false;
          state.message = "Employee creation failed";
          toast.error(state.message);
          break;
      }
    },
    createdProcurement: (state, action) => {
      const { status, id, ...payload } = action.payload;
      state.endPoint = "api/v1/create_procurement";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Procurement  created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.serviceData = {
            ...state.serviceData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Procurement creation failed";
          toast.error(state.message);
      }
    },
    addServiceMember: (state, action) => {
      const { status, id, data, ...payload } = action.payload;
      state.endPoint = "api/v1/add_service_member";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Service Member created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.serviceMemberCreated = true;
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Service Member creation failed";
          toast.error(state.message);
      }
    },
    updateRequestDetails: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.message = "Request details fetched successfully";
        state.requestDetailsData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.isLoading = false;
      }
    },
    updateStatus: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/personal/requests/{id}"
        state.message = 'Status updated successfully'
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.isLoading = false;
      }
    },
    updatedProcurement: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_procurement/{id}"
        state.message = 'procurement updated successfully'
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_procurement/{id}";
        state.isLoading = false;
      }
    },
    updatedUsegesService: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_usage_service${id}"
        state.message = 'Usages Service updated successfully'
        toast.success(state.message, {
          icon: ({theme, type}) =>  <img className="w-8 h-8" src={SuccessIcon} alt="success icon"/>
        });        
        state.UserServiceData = true
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_usage_service${id}";
        state.isLoading = false;
      }
    },
    updatedContractData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_contract/${id}"
        state.message = 'Contract Data updated successfully'
        toast.success(state.message, {
          icon: ({theme, type}) =>  <img className="w-8 h-8" src={SuccessIcon} alt="success icon"/>
        });         
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_contract/${id}";
        state.isLoading = false;
      }
    },
    editContractDetails: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/edit_contract/${id}"
        state.message = 'Contract Data updated successfully'
        toast.success(state.message, {
          icon: ({theme, type}) =>  <img className="w-8 h-8" src={SuccessIcon} alt="success icon"/>
        });         
        state.isLoading = false
        state.createContract = true
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_contract/${id}";
        state.isLoading = false;
      }
    },
    updatedContractFileData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_contract/${id}"
        state.message = 'Contract Data updated successfully'
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_contract/${id}";
        state.isLoading = false;
      }
    },
    updateOpportunityStatus: (state, action) =>{
      if(action.payload){
        state.success = true
        state.message = 'Status updated successfully'
      }else {
        state.success = false;
      }
    },
    deactivatedService: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/deactivate_service/${id}"
        state.message = 'Deactivated Service successfully'
        state.isLoading = false
        state.serviceMenuUpdated = true;
      }else {
        state.success = false;
        state.endPoint = "api/v1/deactivate_service/${id}";
        state.isLoading = false;
      }
    },
    activatedService: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/activate_service/${id}"
        state.message = 'Activated Service successfully'
        state.isLoading = false
        state.serviceMenuUpdated = true;
      }else {
        state.success = false;
        state.endPoint = "api/v1/activate_service/${id}";
        state.isLoading = false;
      }
    },
    deletedService: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/destroy_service/#{id}"
        state.message = 'Delated Service successfully'
        state.isLoading = false
        state.serviceMenuUpdated = true;
      }else {
        state.success = false;
        state.endPoint = "api/v1/destroy_service/#{id}";
        state.isLoading = false;
      }
    },
    updatedServiceName: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_service_name/${id}"
        state.message = 'Service Name updated successfully'
        state.isLoading = false
        state.serviceUpdated = true;
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_service_name/${id}";
        state.isLoading = false;
      }
    },
    updatedServiceCategory: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_service_name/${id}"
        state.message = 'Service Category updated successfully'
        state.isLoading = false
        state.serviceUpdated = true;
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_service_name/${id}";
        state.isLoading = false;
      }
    },
    updateMemberPermission: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/update_service_member_permission"
        state.message = 'Status updated successfully'
        state.isLoading = false
        state.serviceMemberUpdated = true
      }else {
        state.success = false;
        state.endPoint = "api/v1/update_service_member_permission";
        state.isLoading = false;
      }
    },
    deletedServiceMember: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/destroy_service_member"
        state.message = 'Delated Service Member successfully'
        state.isLoading = false
        state.serviceMemberUpdated = true
      }else {
        state.success = false;
        state.endPoint = "api/v1/destroy_service_member";
        state.isLoading = false;
      }
    },
    
    deletedContract: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/delete_contract/${contractId}"
        state.message = 'Delated Contract successfully'
        toast.success(state.message, {
          icon: ({theme, type}) =>  <img className="w-8 h-8" src={SuccessIcon} alt="success icon"/>
        });
        state.isLoading = false
      }
    },
    servicesDetailData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/company/services/company_services"
        state.message = 'fetched successfully'
        state.servicesData = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/company/services/company_services";
        state.isLoading = false;
      }
    },
    servicesSortedDetailData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/company/services/company_services"
        state.message = 'fetched successfully'
        state.servicesData = action.payload
        
        state.servicesData.company_services = state.servicesData.company_services.sort(
          (a, b) => b.cost_per_month - a.cost_per_month
        )
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/company/services/company_services";
        state.isLoading = false;
      }
    },
    SetServicesData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/setup_services"
        state.message = 'fetched successfully'
        // state.setupServiceData = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/setup_services";
        state.isLoading = false;
      }
    },
    authorizedServices: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/authorized_services"
        state.message = 'fetched successfully'
        state.authorizedServicesData = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/authorized_services";
        state.isLoading = false;
      }
    },
    handleDownloadFile: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/download_file/${id}"
        state.message = 'fetched successfully'
        state.downloadFileData = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/download_file/${id}";
        state.isLoading = false;
      }
    },
    InitializeServicesData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/initialize_services_data"
        state.message = 'fetched successfully'
        state.CategoriesAndUsersData = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/initialize_services_data";
        state.isLoading = false;
      }
    },
    allEmpData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/all_employees"
        state.message = 'fetched successfully'
        state.allEmployees = action.payload
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "/api/v1/all_employees";
        state.isLoading = false;
      }
    },
    deletedUserData: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/personal/users/${userId}/delete_user"
        state.message = 'Delated User successfully'
        state.isLoading = false
        state.userMenuUpdated = true
      }else {
        state.success = false;
        state.endPoint = "/api/v1/personal/users/${userId}/delete_user";
        state.isLoading = false;
      }
    },
    updateUserRole: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/personal/users/${user_id}/update_user_role"
        state.message = 'Status updated successfully'
        state.isLoading = false
        state.userMenuUpdated = true
      }else {
        state.success = false;
        state.endPoint = "/api/v1/personal/users/${user_id}/update_user_role";
        state.isLoading = false;
      }
    },
    deactivatedEmployee: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/personal/users/${user_id}/deactivate_employee"
        state.message = 'Status updated successfully'
        state.isLoading = false
        state.userMenuUpdated = true
      }else {
        state.success = false;
        state.endPoint = "/api/v1/personal/users/${user_id}/deactivate_employee";
        state.isLoading = false;
      }
    },
    activatedEmployee: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "/api/v1/personal/users/${user_id}/activate_employee"
        state.message = 'Status updated successfully'
        state.isLoading = false
        state.userMenuUpdated = true
      }else {
        state.success = false;
        state.endPoint = "/api/v1/personal/users/${user_id}/activate_employee";
        state.isLoading = false;
      }
    },
    setUserServiceFalse: (state, action) => {
      state.UserServiceData = false;
    },
    setServiceContractFasle:(state, action) => {
      state.createContract = false;
    },
    setUserMenuUpdatedFalse: (state, action) => {
      state.userMenuUpdated = false;
    },
    setServiceMenuUpdatedFalse: (state, action) => {
      state.serviceMenuUpdated = false;
    },
    setServiceMemberUpdatedFalse: (state, action) => {
      state.serviceMemberUpdated = false;
    },
    setServiceMemberCreatedFalse: (state, action) => {
      state.serviceMemberCreated = false;
    },
    setServiceUpdatedFalse: (state, action) => {
      state.serviceUpdated = false;
    },
    setServiceCreatedFalse: (state, action) => {
      state.serviceCreated = false;
    },
    updatedpersonalServices: (state, action) => {
      state.servicesData.company_services = action.payload;
    },
    updatedSubmission: (state, action) => {
      state.SubmissionsListData.submitted_services = action.payload;
    },
    updateEmployeeSubmission: (state, action) => {
      state.employeeSubmissionData.submissions = action.payload;
    },
    updateUserRequest: (state, action) => {
      if (state.usageReportServiceData) {
        state.usageReportServiceData.service_users = action.payload;
      }
    },
    updatedEmployees: (state, action) => {
      if (state.employeesData) {
        state.employeesData = action.payload;
      }
    },
    updateRequests: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/requests";
        state.message = "Requests fetched successfully";
        state.requestsData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests";
        state.isLoading = false;
      }
    },

    updatedCompanyRequests: (state, action) => {
      if (action.payload) {
        state.employeeRequestData = {
          ...state.employeeRequestData,
          requests: action.payload
        };
      }
    },

    updatedEmployeeRequests: (state, action) => {
      if (action.payload) {
        state.usageReportRequestData = {
          ...state.usageReportRequestData,
          requests: action.payload
        };
      }
    },

    employeeSubmissions: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:id/submissions";
        state.message = "Requests fetched successfully";
        state.employeeSubmissionData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/submissions";
        state.isLoading = false;
      }
    },

    companyIcon: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/upload_company_icon";
        state.message = action?.payload?.data?.message;
        toast.success(state.message);
      } else {
        state.success = false;
        state.endPoint = "api/v1/upload_company_icon";
        state.message = action?.payload?.data?.error;
        toast.error(state.message);
      }
    },

    costVisibility: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/cost_visibility";
        state.message = action?.payload?.data?.message;
        toast.success(state.message);
      } else {
        state.success = false;
        state.endPoint = "api/v1/cost_visibility";
        state.message = action?.payload?.data?.error;
        toast.error(state.message);
      }
    },

    costVisibilityData: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/get_cost_visibility";
        state.serviceCostVisibility = action?.payload
        state.message = action?.payload?.data?.message;
      } else {
        state.success = false;
        state.endPoint = "api/v1/get_cost_visibility";
        state.message = action?.payload?.data?.error;
      }
    },

    costOrgVisibility: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/cost_visibility";
        state.message = action?.payload?.data?.message;
        toast.success(state.message);
      } else {
        state.success = false;
        state.endPoint = "api/v1/cost_visibility";
        state.message = action?.payload?.data?.error;
        toast.error(state.message);
      }
    },

    costOrgVisibilityData: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/get_cost_visibility";
        state.orgCostVisibility = action?.payload
        state.message = action?.payload?.data?.message;
      } else {
        state.success = false;
        state.endPoint = "api/v1/get_cost_visibility";
        state.message = action?.payload?.data?.error;
      }
    },

    empSubmission: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:id/submissions?submission_id";
        state.message = "Requests fetched successfully";
        state.empSubmissionDetail = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/submissions?submission_id";
        state.isLoading = false;
      }
    },

    employeeFeedbacks: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:id/feedbacks";
        state.message = "Requests fetched successfully";
        state.employeefeedbackData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/feedbacks";
        state.isLoading = false;
      }
    },

    teamSubmissions: (state, action) => {
      if (action.payload) {
        state.employeeSubmissionData.submissions = action.payload.submissions
        state.employeeSubmissionData.total_count = action.payload.total_count
        state.employeeSubmissionData.user_name = action.payload.user_name
      }
    },

    teamFeedbacks: (state, action) => {
      if (action.payload) {
        state.employeefeedbackData = action.payload
      }
    },

    teamRequests: (state, action) => {
      if (action.payload) {
        state.employeeRequestData.requests = action.payload.requests
        state.employeeRequestData.total_count = action.payload.total_count
        state.employeeRequestData.user_name =action.payload.user_name
      }
    },

    employeeFeedbackslist: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/company/employees/:enpid/feedbacks?feedback_id=:feedid";
        state.message = "Requests fetched successfully";
        state.employeefeedbacksingle = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/company/employees/:id/feedbacks";
        state.isLoading = false;
      }
    },

    setEmptyUsageReport: (state) => {
      state.usageReportData = {}
    },

    setEmptyEmployeeData: (state) => {
      state.employeeData = undefined;
    },

    setEmptyUsageCompanyReportData: (state) => {
      state.usagecompanyReportData = undefined;
    },

  },
});

export const {
  startLoading,
  updateData,
  deleteManagerName,
  employeeRequests,
  employeeSubmissions,
  companyIcon,
  costVisibility,
  costVisibilityData,
  costOrgVisibility,
  costOrgVisibilityData,
  empSubmission,
  employeeData,
  updateEmployees,
  updateSortedEmployees,
  employeeFeedbacks,
  teamSubmissions,
  teamFeedbacks,
  teamRequests,
  employeeFeedbackslist,
  updateStatus,
  createRequestComment,
  createService,
  updaterequestData,
  updateRequestDetails,
  updateRequests,
  updatedCompanyRequests,
  updatedEmployeeRequests,
  servicesDetailData,
  servicesSortedDetailData,
  updatedpersonalServices,
  updateUserRequest,
  updatedEmployees,
  updatedSubmission,
  updateEmployeeSubmission,
  authorizedServices,
  InitializeServicesData,
  allEmpData,
  deactivatedEmployee,
  activatedEmployee,
  deactivatedService,
  activatedService,
  deletedService,
  serviceUseges,
  contractData,
  employeeFileData,
  createdProcurement,
  updatedProcurement,
  updatedUsegesService,
  updatedContractData,
  updatedContractFileData,
  setServiceCreatedFalse,
  setServiceUpdatedFalse,
  setServiceMemberCreatedFalse,
  setServiceMemberUpdatedFalse,
  setServiceMenuUpdatedFalse,
  setUserMenuUpdatedFalse,
  setUserServiceFalse,
  setServiceContractFasle,
  handleDownloadFile,
  deletedServiceMember,
  deletedContract,
  deletedUserData,
  updateUserRole,
  addServiceMember,
  serviceMember,
  ServiceTemplate,
  EmployeeTemplate,
  updateMemberPermission,
  updatedServiceName,
  updatedServiceCategory,
  savingsOpportunities,
  getUsegesServiceData,
  getServiceContractData,
  getContractdata,
  manageServicesDataScore,
  notifyMultiServiceOwner,
  notifyServiceOwner,
  notifyPublishReport,
  setEmptyUsageReport,
  setEmptyEmployeeData,
  setEmptyUsageCompanyReportData,
  notifyPublishMonthlyReport,
  updateOpportunityStatus,
  editContractDetails
} = companyDashboardSlice.actions;

export const selectCompanyDashboard = (state) => state.companyDashboard;

const fetchData = (url, type) => (dispatch) => {
  dispatch(startLoading());
  fetchWithToken(url, dispatch, (data) => {
    return updateData({ type, data })
  });
};

export const getYearlyUsegesServiceData = (service_id, year) => (dispatch) => {
  fetchWithToken(`/api/v1/yearly_user_service_details/${service_id}?year=${year}`, dispatch, getUsegesServiceData);
};

export const getServiceContract = (service_id) => (dispatch) => {
  fetchWithToken(`/api/v1/service_contract/${service_id}`, dispatch, getServiceContractData);
};

export const getContractData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/get_contract/${id}`, dispatch, getContractdata);
};

export const downloadFile = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/download_file/${id}`, dispatch, handleDownloadFile);
};


export const getAuthorizedServices = () => (dispatch) => {
  fetchWithToken(`/api/v1/authorized_services`, dispatch, authorizedServices);
};

export const getInitializeServicesData = () => (dispatch) => {
  fetchWithToken(`/api/v1/initialize_services_data`, dispatch, InitializeServicesData);
};

export const getAll_employees = () => (dispatch) => {
  fetchWithToken(`/api/v1/all_employees`, dispatch, allEmpData);
};

export const createServices = (serviceData) => (dispatch) => {
  postWithToken(`api/v1/add_services`, serviceData, dispatch, createService);
};

export const createProcurement = (serviceData) => (dispatch) => {
  postWithToken(`api/v1/create_procurement`, serviceData, dispatch, createdProcurement);
};

export const updateProcurement = (id, serviceData) => (dispatch) => {
  patchWithToken(`api/v1/update_procurement/${id}`, serviceData, dispatch, updatedProcurement);
};

export const updateServiceOwner = (id, servicesData ) => (dispatch) => {
  patchWithToken(`api/v1/update_service_owner/${id}`, servicesData, dispatch, updateStatus);
};

export const updateServices = (id, servicesData ) => (dispatch) => {
  patchWithToken(`api/v1/update_service/${id}`, servicesData, dispatch, updateStatus);
};

export const createCustomerServiceUseges = (usegesData) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  postRequest(`api/v1/create_useges_services`, usegesData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(serviceUseges(res));
  });
};

export const updateCustomerServiceUseges = (id, usegesData) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  patchRequest(`api/v1/update_usage_service/${id}`, usegesData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(updatedUsegesService(res));
  });
};

export const updateCustomerServiceUsegesReport = (id, usegesData) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  patchRequest(`api/v1/update_usage_service_report/${id}`, usegesData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(updatedUsegesService(res));
  });
};

export const createContractData = (Data) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  postRequest(`api/v1/create_contract`, Data, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(contractData(res));
  });
};

export const editContractData = (id, Data) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  patchRequest(`/api/v1/edit_contract/${id}`, Data, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(editContractDetails(res));
  });
};

export const updateContractData = (id, usegesData) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  patchRequest(`api/v1/update_contract/${id}`, usegesData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(updatedContractData(res));
  });
};

export const updateContractFile = (id, usegesData) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  patchRequest(`api/v1/update_contract_file/${id}`, usegesData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(updatedContractFileData(res));
  });
};

export const createEmployeeFile = (Data) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  postRequest(`api/v1/employee_files`, Data, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(employeeFileData(res));
  });
};

export const createServiceMember = (memberData) => (dispatch) => {
  postWithToken(`api/v1/add_service_member`, memberData, dispatch, addServiceMember);
};

export const updateServiceMemberPermission = (memberData) => (dispatch) => {
  patchWithToken(`api/v1/update_service_member_permission`, memberData, dispatch, updateMemberPermission);
};

export const updateEmployeeRole = (user_id ,user_role) => (dispatch) => {
  patchWithToken(`/api/v1/personal/users/${user_id}/update_employee_role`,user_role , dispatch, updateUserRole);
};

export const disableEmployee = (userId) => (dispatch) => {
  deleteWithToken(`/api/v1/personal/users/${userId}/disable_employee`, dispatch, deletedUserData  );
};

export const deactivateEmpReport = (user_id) => (dispatch) => {
  patchWithToken(`/api/v1/personal/users/${user_id}/deactivate_employee`,{}, dispatch, deactivatedEmployee);
};

export const activateEmpReport = (user_id) => (dispatch) => {
  patchWithToken(`/api/v1/personal/users/${user_id}/activate_employee`,{}, dispatch, activatedEmployee);
};

export const updateServicename = (id, servicesData) => (dispatch) => {
  patchWithToken(`/api/v1/update_service_name/${id}`,servicesData, dispatch, updatedServiceName);
};

export const updateServicecategory = (id, servicesData) => (dispatch) => {
  patchWithToken(`/api/v1/update_service_category/${id}`,servicesData, dispatch, updatedServiceCategory);
};

export const getServiceMembers = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/service_members/${id}`, dispatch, serviceMember);
};

export const getServiceTemplate = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/template/${id}`, dispatch, ServiceTemplate);
};

export const getEmployeeTemplate = () => (dispatch) => {
  fetchWithToken(`/api/v1/employee_template`, dispatch, EmployeeTemplate);
};

export const deleteServiceMembers = (serviceId,userId) => (dispatch) => {
  deleteWithToken(`/api/v1/destroy_service_member/${serviceId}?user_id=${userId}`, dispatch, deletedServiceMember  );
};

export const deleteContract = (contractId) => (dispatch) => {
  deleteWithToken(`/api/v1/delete_contract/${contractId}`, dispatch, deletedContract  );
};

export const deactivateService = (id) => (dispatch) => {
  patchWithToken(`/api/v1/deactivate_service/${id}`,{}, dispatch, deactivatedService);
};

export const activateService = (id) => (dispatch) => {
  patchWithToken(`/api/v1/activate_service/${id}`,{}, dispatch, activatedService);
};

export const deleteService = (id) => (dispatch) => {
  deleteWithToken(`/api/v1/destroy_service/${id}`, dispatch, deletedService  );
};

export const teamEmpRequestsData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/personal/users/${id}/employee_requests`, dispatch, teamRequests );
};

export const employeeRequestsData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees/${id}/requests`, dispatch, employeeRequests);
};

export const createRequestComments = (id, requestData) => (dispatch) => {
  postWithToken(`api/v1/personal/requests/${id}/comment`, requestData, dispatch, createRequestComment);
};

export const updateRequest = (id, requestData) => (dispatch) => {
  postWithToken(`api/v1/personal/requests/:${id}`, requestData, dispatch, updaterequestData);
};

export const getRequestDetailsdata = (empid, reqid) => (dispatch) => {
  fetchWithToken(`api/v1/company/employees/${empid}/requests?request_id=${reqid}`, dispatch, updateRequestDetails);
};

export const statusUpdateRequest = (id, requestData ) => (dispatch) => {
  patchWithToken(`api/v1/personal/requests/${id}`, requestData, dispatch, updateStatus);
};

export const UploadCompanyIcon = (image) => (dispatch) => {
  const token = localStorage.getItem("accessToken");
  const formData = new FormData();
  formData.append("image", image);

  patchRequest(`api/v1/upload_company_icon`, formData, {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  }).then((res) => {
    dispatch(companyIcon(res));
  });
};

export const UpdateCostVisibility = (id, cost) => (dispatch) => {
  const visible = {
    "service_id": id,
    "cost_visibility": cost
  }

  patchWithToken(`api/v1/cost_visibility`, visible, dispatch, costVisibility);
};

export const getCostVisibility = (id) => (dispatch) => {
  fetchWithToken(`api/v1/get_cost_visibility?service_id=${id}`, dispatch, costVisibilityData);
};

export const UpdateOrgCostVisibility = (cost) => (dispatch) => {
  const visible = {
    "cost_visibility": cost
  }

  patchWithToken(`api/v1/org_cost_visibility`, visible, dispatch, costOrgVisibility);
};

export const getOrgCostVisibility = (id) => (dispatch) => {
  fetchWithToken("api/v1/get_org_cost_visibility", dispatch, costOrgVisibilityData);
};

export const getComRequestsPagewithoutParams = () => (dispatch) => {
  fetchWithToken("api/v1/personal/requests", dispatch, updateRequests);
};

export const employeeSubmissionsData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees/${id}/submissions`, dispatch, employeeSubmissions);
};

export const empSubmissionData = (id, submission_id) => (dispatch) => {
  fetchWithToken(`api/v1/company/employees/${id}/submissions?submission_id=${submission_id}`, dispatch, empSubmission);
};

export const teamEmpSubmissionsData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/personal/users/${id}/employee_submissions`, dispatch, teamSubmissions);
};

export const teamEmpFeedbacksData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/personal/users/${id}/employee_feedbacks`, dispatch, teamFeedbacks);
}

export const employeeFeedbacksData = (id) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees/${id}/feedbacks`, dispatch, employeeFeedbacks);
}

export const employeeFeedbacksDatalist = (enpid, feedid) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees/${enpid}/feedbacks?feedback_id=${feedid}`, dispatch, employeeFeedbackslist);
}

export const companySubmissionDetail = (id, userid) => fetchData(`api/v1/company/submissions/${id}?user_id=${userid}`, 'companySubmissionDetailData');



export const getSetupServicesData = (id) => fetchData(`/api/v1/setup_services/${id}`, 'SetServicesData');

export const getServicesData = () => (dispatch) => {
  fetchWithToken('/api/v1/company/services/company_services', dispatch, servicesDetailData);
}
export const getServicesSortedData = () => (dispatch) => {
  fetchWithToken('/api/v1/company/services/company_services', dispatch, servicesSortedDetailData);
}
export const getUsageRequestDataDetails = (id, reqid) => (dispatch) => {
  fetchWithToken(`api/v1/company/services/${id}/usage_report/requests?request_id=${reqid}`, dispatch, updateRequestDetails);
}

// export const getUsageRequestDataDetails =(id, reqid) => fetchData(`api/v1/company/services/${id}/usage_report/requests?request_id=${reqid}`, 'updateRequestDetails')
export const getUsageserviceDataDetails = (id, userid) => fetchData(`api/v1/company/services/${id}/usage_report/service_users?s_user_id=${userid}`, 'usagecompanyReportData');

export const getCompanyUsageReportData = (id, empid) => fetchData(`api/v1/services/${id}/employees/${empid}/usage_report`, 'usagecompanyReportData');

export const getCompanyServicesRequest = (id, empid) =>  fetchData(`api/v1/services/${id}/employees/${empid}/requests`, 'usageReportRequestData');
export const getCompanyServicesFeedback = (id,empid) => fetchData(`api/v1/services/${id}/employees/${empid}/feedbacks`, 'usageReportFeedbacksData');
export const getCompanyServiceCostHistory = (id, empid) => fetchData(`api/v1/services/${id}/employees/${empid}/costs_history`, 'empServiceHistoryData');

export const getHomeData = () => fetchData('/api/v1/company_dashboard', 'homeData');
export const getEmployeeSpecficData = (id) => fetchData(`/api/v1/company/employees/${id}`, 'employeeData');

export const getEmployeeData = (userId) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees?user_id=${userId}`, dispatch, updateEmployees);
}

export const getEmployeeSortedData = (userId) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees?user_id=${userId}`, dispatch, updateSortedEmployees);
}

export const getSavingsOpportunities = () => (dispatch) => {
  fetchWithToken(`/api/v1/savings_opportunities`, dispatch, savingsOpportunities);
}

export const getManageServicesDataScore = () => (dispatch) =>{
  fetchWithToken(`/api/v1/manage_service_data_score`, dispatch, manageServicesDataScore);
}

export const notifySingleServiceOwner = (service_id) => (dispatch) =>{
  postWithToken(`/api/v1/notify_service_owners/${service_id}`,{},dispatch, notifyServiceOwner)
}

export const notifyMultipleServiceOwner = (service_id) => (dispatch) =>{
  postWithToken(`/api/v1/notify_multiple_service_owners/${service_id}`,{}, dispatch, notifyMultiServiceOwner)
}

export const companyPublishReports = () => (dispatch) => {
  patchWithToken(`/api/v1/company_publish_report`,{}, dispatch, notifyPublishReport)
}

export const companyPublishMonthlyReports = (company_data) => (dispatch) => {
  patchWithToken(`/api/v1/company_publish_monthly_report`,company_data, dispatch, notifyPublishMonthlyReport)
}

export const updateSavingOpportunityStatus = (id) => (dispatch) => {
  patchWithToken(`/api/v1/savings_opportunities/${id}/update_opportunity_status`,{}, dispatch, updateOpportunityStatus)
}

export const getSubmissionsData = (id) => fetchData(`api/v1/company/services/${id}/submissions`, 'submissionsData');
export const getCompanyHistoryRequest = () => fetchData('api/v1/company/costs_history', 'CompanyhistoryData');
export const getUsageReportData = (id) => fetchData(`api/v1/company/services/${id}/usage_report`, 'usageReportData');
export const getTeamUsageReportData = (service_id, user_id) => fetchData(`/api/v1/personal/users/${service_id}/team_usage_report?user_id=${user_id}`, 'usageReportData');
export const getCompanySubmissionData = () => fetchData('/api/v1/company/services/service_submissions','SubmissionsListData');

export const getUsageFeedbackData = (id) => fetchData(`api/v1/company/services/${id}/usage_report/feedbacks`, 'usageReportFeedbacksData')
export const getUsageSpecificFeedback = (id, feedBackId) => fetchData(`api/v1/company/services/${id}/usage_report/feedbacks?feedback_id=${feedBackId}`,'specficFeedbackData')
export const getUsageRequestData = (id) => fetchData(`api/v1/company/services/${id}/usage_report/requests`, 'usageReportRequestData');

export const getUsageServiceData = (id)=> fetchData(`api/v1/company/services/${id}/usage_report/service_users`, 'usageReportServiceData')
export const getUsageTeamServiceUserData = (service_id, user_id)=> fetchData(`/api/v1/personal/users/${service_id}/usage_report_service_users?user_id=${user_id}`, 'usageReportServiceData')
export const getUsageTeamRequestData = (service_id, user_id)=> fetchData(`/api/v1/personal/users/${service_id}/usage_report_requests?user_id=${user_id}`, 'usageReportRequestData')
export const getUsageTeamFeedbackData = (service_id, user_id)=> fetchData(`/api/v1/personal/users/${service_id}/usage_report_feedbacks?user_id=${user_id}`, 'usageReportFeedbacksData')
export default companyDashboardSlice.reducer;
