import React, { useEffect, useState } from "react";
import Button from "../../Buttons/button";
import BasicModal from "../../models/models";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  createFeedbackRequest,
  updatedServices,
  setEmptyUsageReport,
} from "../../../redux/slices/personalDashboardSlice";
import { setEmptyUsageCompanyReportData } from "../../../redux/slices/companyDashboardSlice";
import { formatedAmount } from "../../../utils/helpers";
import negativeIcon from "../../../assets/images/negative.png";
import negativeHoverIcon from "../../../assets/images/nagative_hover.png";
import neutralIcon from "../../../assets/images/neutral.png";
import neutralHoverIcon from "../../../assets/images/neutral_hover.png";
import smileyIcon from "../../../assets/images/smiley.png";
import smileyHoverIcon from "../../../assets/images/smiley_hover.png";

function Card({
  image,
  title,
  price,
  servicesData,
  rating,
  navLink,
  btns,
  modalId,
}) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [id, setId] = useState({ id: modalId });
  const dispatch = useDispatch();

  const handleRatingClick = (newRating) => {
    dispatch(
      createFeedbackRequest({
        service_id: modalId,
        rating: newRating,
      })
    );

    const currentService = [...servicesData?.services].find(
      (el) => +el.id === +modalId
    );
    const servicesWithNewRating = [...servicesData?.services].map((el) =>
      el.id === currentService.id
        ? { ...currentService, rating: newRating }
        : el
    );

    dispatch(updatedServices(servicesWithNewRating));
  };

  const handleEmptyUsageReport = () => {
    dispatch(setEmptyUsageReport());
    dispatch(setEmptyUsageCompanyReportData());
  };

  return (
    <>
      {title && (
        <div
          className={`flex flex-col card-animate gap-[12px] bg-[#363D50] w-full max-w-[100%] h-auto sm:max-w-[calc(50%-24px)] mdl:max-w-[calc(33.33%-30px)] 6xl:max-w-[calc(25%-32px)] ${
            btns ? "pt-[24px] pb-[8px] max-h-[366px]" : "py-[24px]"
          } rounded-[32px] cursor-pointer m-1`}
          onClick={handleEmptyUsageReport}
        >
          <Link to={navLink} className="flex flex-col gap-[12px] px-4">
            <div className="flex justify-center w-full">
              <img
                className="w-[100px] h-[100px] rounded-[16px] card_img"
                src={image}
                alt="service-img"
              />
            </div>
            <div className="flex justify-center w-full">
              <span className="text-secondary text-center">{title}</span>
            </div>
          </Link>

          <div className="flex flex-row justify-center items-center max-h-[73px]">
            <div className="flex flex-col gap-[2px] p-[8px] w-[164.5px] justify-center items-center">
              <span className="text-primary !text-[20px] ">${price}</span>
              <span className="text-secondary">Per month</span>
            </div>
            <div className="w-[1px] h-[40px] bg-[#596073]" />
            <div className="flex flex-col gap-[2px] p-[0px 8px] w-[164.5px] justify-center items-center">
              {btns ? (
                <div className="flex gap-[6px] w-[100%] justify-center items-center">
                  <div
                    onClick={() => handleRatingClick("positive")}
                    className={`${
                      rating === "positive" && "active"
                    } good rating_wrapper `}
                  >
                    <img
                      src={smileyIcon}
                      alt="rating-img"
                      className="rating_icon inactive"
                    />
                    <img
                      src={smileyHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>

                  <div
                    onClick={() => handleRatingClick("general")}
                    className={`${
                      rating === "general" && "active"
                    } average rating_wrapper `}
                  >
                    <img
                      src={neutralIcon}
                      alt="rating-img"
                      className="rating_icon inactive"
                    />
                    <img
                      src={neutralHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>

                  <div
                    onClick={() => handleRatingClick("negative")}
                    className={`${
                      rating === "negative" && "active"
                    } bad rating_wrapper `}
                  >
                    <img
                      src={negativeIcon}
                      alt="rating-img"
                      className="rating_icon inactive"
                    />
                    <img
                      src={negativeHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex w-[24px] h-[24px] justify-center items-center">
                  {rating === "negative" && (
                    <img
                      src={negativeHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}

                  {rating === "general" && (
                    <img
                      src={neutralHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}

                  {rating === "positive" && (
                    <img
                      src={smileyHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}
                </div>
              )}
              <span className="text-secondary">Rate Impact</span>
            </div>
          </div>

          {btns && <div className="h-[1px] bg-[#596073]" />}
          {btns && (
            <div className="flex flex-col justify-center w-full max-h-[80px] py-[10px] pl-[24px] pr-[24px] gap-[10px]">
              <Button
                onClick={() => {
                  setPopup(true);
                  setState(4);
                }}
                secondaryHover={true}
                position={"left"}
                className="w-[100%!important]"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.5 7C3.5 6.17157 4.17157 5.5 5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V15C20.5 15.8284 19.8284 16.5 19 16.5H14.8284C14.1654 16.5 13.5295 16.7634 13.0607 17.2322L9.14645 21.1464C8.95118 21.3417 8.95118 21.6583 9.14645 21.8536C9.34171 22.0488 9.65829 22.0488 9.85355 21.8536L13.7678 17.9393C14.0491 17.658 14.4306 17.5 14.8284 17.5H19C20.3807 17.5 21.5 16.3807 21.5 15V7C21.5 5.61929 20.3807 4.5 19 4.5H5C3.61929 4.5 2.5 5.61929 2.5 7V15C2.5 16.3807 3.61929 17.5 5 17.5H8.5C8.77614 17.5 9 17.2761 9 17C9 16.7239 8.77614 16.5 8.5 16.5H5C4.17157 16.5 3.5 15.8284 3.5 15V7ZM8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5H8.01C8.28614 11.5 8.51 11.2761 8.51 11C8.51 10.7239 8.28614 10.5 8.01 10.5H8ZM12 10.5C11.7239 10.5 11.5 10.7239 11.5 11C11.5 11.2761 11.7239 11.5 12 11.5H12.01C12.2861 11.5 12.51 11.2761 12.51 11C12.51 10.7239 12.2861 10.5 12.01 10.5H12ZM16 10.5C15.7239 10.5 15.5 10.7239 15.5 11C15.5 11.2761 15.7239 11.5 16 11.5H16.01C16.2861 11.5 16.51 11.2761 16.51 11C16.51 10.7239 16.2861 10.5 16.01 10.5H16Z"
                    fill="white"
                    className="group-hover:fill-[#FFD74A]"
                  />
                </svg>
                Make a Request
              </Button>
              <Button
                onClick={() => {
                  setPopup(true);
                  setState(12);
                }}
                secondaryHover={true}
                className="w-[100%!important]"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.93303 6.87691C7.54534 6.49622 8.2922 6.39841 8.98189 6.60861L14.0119 8.14162C14.7016 8.35184 15.0414 9.12775 14.728 9.77719C14.4842 10.2825 13.9173 10.5451 13.3742 10.4044L10.8502 9.75052C10.7993 9.7368 10.7479 9.73148 10.6976 9.73379C10.546 9.74065 10.4056 9.81667 10.3167 9.93809C10.2841 9.98266 10.2584 10.0333 10.2417 10.089C10.2249 10.1446 10.2182 10.201 10.2208 10.2562C10.2299 10.4585 10.3623 10.641 10.5614 10.7075L12.5717 11.4019C13.2408 11.633 13.9643 11.6558 14.6466 11.4672L20.0238 9.98134C20.4014 9.877 20.804 10.0182 21.0337 10.3356C21.3423 10.7619 21.2399 11.3585 20.8069 11.6577L14.4845 16.0254C13.8754 16.4461 13.1112 16.5753 12.3977 16.3781L6.90309 14.8598C6.59945 14.7759 6.28588 14.7334 5.97087 14.7334H3.48264C3.2065 14.7334 2.98264 14.9573 2.98264 15.2334C2.98264 15.5095 3.2065 15.7334 3.48264 15.7334H5.97087C6.19588 15.7334 6.41986 15.7638 6.63674 15.8237L12.1313 17.342C13.1303 17.6181 14.2002 17.4372 15.0529 16.8481L21.3753 12.4804C22.2698 11.8625 22.4812 10.6299 21.8438 9.74927C21.3693 9.09369 20.5375 8.80191 19.7574 9.01746L15.657 10.1505C16.187 8.96161 15.5582 7.5675 14.3034 7.18506L9.27343 5.65205C8.30786 5.35777 7.26226 5.4947 6.40503 6.02767L2.73613 8.30878C2.50161 8.45459 2.4297 8.76289 2.57551 8.99741C2.72131 9.23192 3.02962 9.30383 3.26413 9.15802L6.93303 6.87691Z"
                    fill="white"
                    className="group-hover:fill-[#FFD74A]"
                  />
                </svg>
                Give Feedback
              </Button>
            </div>
          )}
          {setPopup && (
            <BasicModal
              id={id}
              setId={setId}
              state={state}
              popup={popup}
              setPopup={setPopup}
              setState={setState}
            />
          )}
        </div>
      )}
      {!title && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          <div
            className={`flex flex-col items-center gap-6 pt-8 p-6 h-[278px] card-animate bg-[#363D50] w-full max-w-[100%] sm:max-w-[calc(50%-24px)] mdl:max-w-[calc(33.33%-30px)] 6xl:max-w-[calc(25%-32px)] rounded-[32px] cursor-pointer m-1`}
          >
            <Skeleton className="!w-[126px] h-[132px] !rounded-lg" />
            <div className="flex items-center justify-between gap-12 w-full">
              <div className="flex flex-col gap-3 items-center w-full">
                <Skeleton className="!w-[112px]" />
                <Skeleton className="!w-[112px]" />
              </div>
              <div className="flex flex-col gap-3 items-center">
                <Skeleton className="!w-[112px]" />
                <Skeleton className="!w-[112px]" />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      )}
    </>
  );
}

export default Card;
